.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin:30px auto;
  position: relative;
  color: #FFF;
  left: -120px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
  /* margin-top: 10px; */
  justify-content: center;
  align-items: center;
}

@keyframes shadowRolling {
 0% {
  box-shadow: 0px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128);
}
12% {
  box-shadow: 100px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128);
}
25% {
  box-shadow: 110px 0 rgb(6, 18, 128), 100px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128);
}
36% {
  box-shadow: 120px 0 rgb(6, 18, 128), 110px 0 rgb(6, 18, 128), 100px 0 rgb(6, 18, 128), 0px 0 rgb(6, 18, 128);
}
50% {
  box-shadow: 130px 0 rgb(6, 18, 128), 120px 0 rgb(6, 18, 128), 110px 0 rgb(6, 18, 128), 100px 0 rgb(6, 18, 128);
}
62% {
  box-shadow: 200px 0 rgb(6, 18, 128), 130px 0 rgba(27, 38, 55, 0.3), 120px 0 rgba(27, 38, 55, 0.3), 110px 0 rgba(27, 38, 55, 0.3);
}
75% {
  box-shadow: 200px 0 rgb(6, 18, 128), 200px 0 rgb(6, 18, 128), 130px 0 rgba(27, 38, 55, 0.3), 120px 0 rgba(27, 38, 55, 0.3);
}
87% {
  box-shadow: 200px 0 rgb(6, 18, 128), 200px 0 rgb(6, 18, 128), 200px 0 rgb(6, 18, 128), 130px 0 rgba(27, 38, 55, 0.3);
}
100% {
  box-shadow: 200px 0 rgb(6, 18, 128), 200px 0 rgb(6, 18, 128), 200px 0 rgb(6, 18, 128), 200px 0 rgb(6, 18, 128);
}

}
.spanloader{
    height: 600px;
    /* display: block; */
    padding-top: 150px;
     
}